export const adminRoutes = {
  admin: {
    path: 'admin',
    fullPath: '/admin'
  },
  adminUsers: {
    path: 'users',
    fullPath: '/admin/users',
  },
  adminChatRooms: {
    path: 'chat-rooms',
    fullPath: '/admin/chat-rooms',
  },
  adminChatMessages: {
    path: 'chat-messages',
    fullPath: '/admin/chat-messages',
  },
  adminQuestions: {
    path: 'questions',
    fullPath: '/admin/questions',
  },
  adminReports: {
    path: 'reports',
    fullPath: '/admin/reports',
  },
  adminCategories: {
    path: 'categories',
    fullPath: '/admin/categories',
  },
  adminStats: {
    path: 'stats',
    fullPath: '/admin/stats',
  },
  adminInstaChatUsers: {
    path: 'insta-chat-users',
    fullPath: '/admin/insta-chat-users',
  },
  adminAppConfig: {
    path: 'app-config',
    fullPath: '/admin/app-config',
  },
  adminImsoloQuestions: {
    name: '문의 관리',
    path: 'imsolo/questions',
    fullPath: '/admin/imsolo/questions',
  },
  adminMatchingResults: {
    name: '매칭 관리',
    path: 'match/results',
    fullPath: '/admin/match/results',
  },
  adminImsoloPolls: {
    name: '투표 관리',
    path: 'imsolo/polls',
    fullPath: '/admin/imsolo/polls',
  },
  adminImsoloHallOfFames: {
    name: '명예의 전당',
    path: 'imsolo/hall-of-fames',
    fullPath: '/admin/imsolo/hall-of-fames',
  },
  adminImsoloRules: {
    name: '나는솔로 법칙',
    path: 'imsolo/hall-rules',
    fullPath: '/admin/imsolo/hall-rules',
  },
  adminImsoloPerformers: {
    name: '출연자 관리',
    path: 'imsolo/performers',
    fullPath: '/admin/imsolo/performers',
  },
  adminImsoloNameRanking: {
    name: '이름별 순위',
    path: 'imsolo/name-rankings',
    fullPath: '/admin/imsolo/name-rankings',
  },
  adminKpopIdolMatches: {
    name: 'KPOP매치관리',
    path: 'kpop/idol-matches',
    fullPath: '/admin/kpop/idol-matches'
  },
  adminKpopIdolGroups: {
    name: 'KPOP그룹관리',
    path: 'kpop/idol-groups',
    fullPath: '/admin/kpop/idol-groups',
  },
  adminKpopIdolMembers: {
    name: 'KPOP멤버관리',
    path: 'kpop/idol-members',
    fullPath: '/admin/kpop/idol-members',
  },
  adminKpopPhotos: {
    name: 'KPOP Photo',
    path: 'kpop-photos',
    fullPath: '/admin/kpop-photos',
  },
};

